<template>
  <v-card
    class="mx-auto"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class=" mb-4">
          <v-icon>mdi-map-marker</v-icon>
          <span>Bangbaedong, KR</span>
        </div>
        <v-list-item-title class="headline mb-2">24.7°C</v-list-item-title>
        <v-list-item-subtitle>light rain</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar tile size="80">
        <v-img src="http://openweathermap.org/img/w/10d.png"></v-img>
      </v-list-item-avatar>
    </v-list-item>

    <v-list-item>
      <v-list-item-subtitle>
        sunrise AM 05:30,
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        sunset PM 07:40
      </v-list-item-subtitle>
    </v-list-item>
  </v-card>

</template>
<script>
export default {
  props: ['weather'],
  data () {
    return {
      //
    }
  }
}
</script>
