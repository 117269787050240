<template>
  <div>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>
        화이트보드 설정
      </v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-layout column>
        <v-flex>
          <v-subheader class="px-1 my-2">
            날짜표기
          </v-subheader>

          <v-layout wrap>
            <v-row
              align="center"
              no-gutters
            >
              <v-col cols="auto">
                Korean
              </v-col>

              <v-spacer />

              <v-col cols="auto">
                <v-switch
                  v-model="$vuetify.theme.dark"
                  class="ma-0 pa-0"
                  color="secondary"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-layout>
          <v-layout wrap class="mt-4">
            <v-row
              align="center"
              no-gutters
            >
              <v-col cols="auto">
                English
              </v-col>

              <v-spacer />

              <v-col cols="auto">
                <v-switch
                  v-model="$vuetify.theme.dark"
                  class="ma-0 pa-0"
                  color="secondary"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-layout>

          <v-divider class="my-4" />

          <v-subheader class="px-1 my-2">
            날씨 지역
          </v-subheader>

          <v-layout wrap>
            <v-row
              align="center"
              no-gutters
            >

              <v-col cols="auto">
                <v-select
                  v-model="select"
                  :items="items"
                  item-text="kname"
                  item-value="id"
                  solo
                ></v-select>
                {{ select }}
              </v-col>
            </v-row>
          </v-layout>
          <v-layout wrap>
            <v-row
              align="center"
              no-gutters
            >

              <v-col cols="auto">
                <v-btn @click="test">test</v-btn>
              </v-col>
            </v-row>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
export default {
  data () {
    return {
      select: null,
      items: [
        { kname: '서울', id: 1111 },
        { kname: '부산', id: 2222 },
        { kname: '인천', id: 3333 },
        { kname: '대전', id: 4444 }
      ]
    }
  },
  methods: {
    test () {
      this.select = 3333
    }
  }
}
</script>
