<template>
  <div>
    <v-container fluid>

      <v-row justify="center">
        <v-col md="12">

          <v-card outlined class="text-center">
            <v-sheet class="title_box py-5">{{ today }}</v-sheet>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="9">
          <v-card outlined height="100%">

          <v-tabs vertical>
            <v-tab>
              <v-icon left>mdi-text</v-icon>
              메모보드
            </v-tab>
            <v-tab>
              <v-icon left>mdi-image</v-icon>
              이미지
            </v-tab>
            <v-tab>
              <v-icon left>mdi-youtube</v-icon>
              플레이어
            </v-tab>

            <v-tab-item>
              <editor :options="options" :initialValue="editorText" height="600px" initialEditType="wysiwyg"></editor>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <p>
                    이미지
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <audio-player/>
            </v-tab-item>
          </v-tabs>

          </v-card>
        </v-col>
        <v-col cols="3">
          <weather-card :weather="weathers[0]"></weather-card>

          <v-card outlined class="mt-5">
            <v-list-item>
              <span class="text-caption">스티커순</span>
              <v-spacer/>
              <v-btn-toggle
                v-model="toggle_exclusive"
                mandatory
              >
                <v-btn icon small>
                  <v-icon dense color="red darken-2">mdi-stamper</v-icon>
                </v-btn>
                <v-btn icon small>
                  <v-icon dense color="teal darken-2">mdi-file-powerpoint-box</v-icon>
                </v-btn>
              </v-btn-toggle>
              <!-- <v-btn icon small><v-icon dense color="red darken-2">mdi-stamper</v-icon></v-btn>
              <v-btn icon small><v-icon dense color="teal darken-2">mdi-file-powerpoint-box</v-icon></v-btn> -->
            </v-list-item>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon large color="blue darken-2">mdi-numeric-1-box</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title><span class="font-weight-bold">홍길동</span><span style="float:right"><span class="font-weight-bold">40</span>/<span class="text--secondary">50</span></span></v-list-item-title>
                <v-progress-linear value="100"></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon large color="red darken-2">mdi-numeric-2-box</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>홍길동<span style="float:right"><span class="font-weight-bold">25</span>/<span class="text--secondary">50</span></span></v-list-item-title>
                <v-progress-linear value="50" color="red darken-2"></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon large color="green darken-2">mdi-numeric-3-box</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>홍길동<span style="float:right"><span class="font-weight-bold">10</span>/<span class="text--secondary">50</span></span></v-list-item-title>
                <v-progress-linear value="20" color="green darken-2"></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
          </v-card>

          <v-card outlined class="mt-5">
            <v-list-item>
              <v-list-item-content>
                <v-btn>잉글리시아이</v-btn>
                <v-btn>클래스카드</v-btn>
                <v-btn>라즈키즈</v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>

      <v-card id="create">
        <v-speed-dial
        >
          <template v-slot:activator>
            <v-btn
              v-model="fab"
              color="blue darken-2"
              dark
              fab
            >
              <v-icon v-if="fab">mdi-close</v-icon>
              <v-icon v-else>mdi-account-circle</v-icon>
            </v-btn>
          </template>
          <v-btn
            dark
            small
            color="green"
          >
            aaa
          </v-btn>
          <v-btn
            fab
            dark
            small
            color="indigo"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            small
            color="red"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-speed-dial>
      </v-card>
    </v-container>

    <!-- theme setting -->
    <v-btn small fab dark falt fixed top="top" right="right" class="setting-fab" color="red" @click="openThemeSettings">
      <v-icon>mdi-cog</v-icon>
    </v-btn>
    <!-- setting drawer -->
    <v-navigation-drawer class="setting-drawer" temporary right v-model="rightDrawer" hide-overlay fixed>
      <theme-settings></theme-settings>
    </v-navigation-drawer>

    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="300"
        persistent
      >
        <v-card>
          <v-card-title class="headline">약관 동의</v-card-title>
          <v-divider/>
          <v-card-text>
            <p class="text-subtitle-1 pt-5">약관에 동의하셔야 클래스아이의 서비스를 이용하실 수 있습니다.</p>

              <v-btn
                class="text-subtitle-1 pl-0"
                @click="goTerms"
                text
                height="32"
              >
                이용약관 확인하기 <v-icon color="grey" small>mdi-text-box-multiple-outline</v-icon>
              </v-btn>

          </v-card-text>

          <v-card-actions justify="center">
            <v-btn
              color="green darken-1"
              outlined
              @click="accept"
              width="100%"
            >
              약관에 동의합니다
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ThemeSettings from '@/components/ThemeSettings.vue'
import WeatherCard from '@/components/widgets/WeatherCard'
import AudioPlayer from '@/components/widgets/AudioPlayer'

export default {
  components: {
    ThemeSettings, WeatherCard, AudioPlayer
  },
  data () {
    return {
      options: {
        minHeight: '400px',
        language: 'ko'
      },
      editorText: '#메모를 하세요',
      toggle_exclusive: 0,
      rightDrawer: false,
      fab: false,
      today: '',
      acceptTerms: 'N',
      dialog: false,
      locationsDetails: [],
      weathers: [
        // {
        //   id: 1835847,
        //   name: 'Seoul',
        //   kname: '서울',
        //   coords: {
        //     lon: 127,
        //     lat: 37.583328
        //   },
        //   country: 'KR',
        //   order: 1
        // }
      ],
      songUrl: 'https://firebasestorage.googleapis.com/v0/b/classi-lab.appspot.com/o/test%2F193_hair_of_the_dog.mp3?alt=media&token=c0c8af37-b8bb-40e4-b41c-382e516a42d6'
    }
  },
  created () {
    this.read()
  },
  methods: {
    read () {
      this.checkTerms()
      this.getDate()
      this.getWeather()
    },
    async checkTerms () {
      const user = this.$firebase.auth().currentUser
      const r = await this.$firebase.firestore().collection('users').doc(user.uid).get()

      if (r.exists) {
        this.acceptTerms = r.data().acceptTerms
      }

      if (this.acceptTerms === undefined || this.acceptTerms === 'N') this.dialog = true
    },
    getDate () {
      const d = this.$moment()
      this.today = d.format('YYYY년 M월 D일(dd)')
      // console.log(this.$moment.locale)
      // console.log(d.format('LL'))
      // console.log(d.format('YYYY년 MM월 DD일(dd)'))
      // console.log(d.locale('en').format('LL'))
      // console.log(d.locale('en').format('dddd, LL'))
      // console.log(this.$moment.locale)
    },
    getWeather () {
      //
    },
    openThemeSettings () {
      this.$vuetify.goTo(0)
      this.rightDrawer = !this.rightDrawer
    },
    accept () {
      this.dialog = false

      const user = this.$firebase.auth().currentUser

      this.$firebase.firestore().collection('users').doc(user.uid).get()
        .then((r) => {
          if (r.exists) {
            this.$firebase.firestore().collection('users').doc(user.uid).update({
              acceptTerms: 'Y'
            })
          }
        })
    },
    goTerms () {
      window.open('/terms', 'Popup')
    }
  }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nanum+Gothic:400,700,800');

.setting-fab {
  top: 50% !important;
  right: 0;
  border-radius: 0;
}
.title_box {
  font-family : 'Nanum Gothic';
  text-align : center;
  font-weight: 700;
  font-size: 2.5em;
}

#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}

</style>
