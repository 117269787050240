<template>
  <div>
    <v-card>
      <v-card-title class="song-title">
        test
      </v-card-title>
      <v-card-actions class="controls">
        <v-btn v-if="!isPlaying" fab large @click="play"><v-icon>mdi-play</v-icon></v-btn>
        <v-btn v-else fab large @click="stop"><v-icon>mdi-pause</v-icon></v-btn>
      </v-card-actions>
    </v-card>

  </div>
</template>

<script>
export default {
  data () {
    return {
      isPlaying: false,
      player: new Audio()
    }
  },
  methods: {
    play () {
      this.isPlaying = !this.isPlaying
      this.player.src = 'https://firebasestorage.googleapis.com/v0/b/classi-lab.appspot.com/o/test%2F193_hair_of_the_dog.mp3?alt=media&token=c0c8af37-b8bb-40e4-b41c-382e516a42d6'
      this.player.play()
    },
    stop () {
      this.isPlaying = !this.isPlaying
      this.player.pause()
    }
  }
}
</script>

<style scoped>

  .song-title{
    color: #53565A;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }
  .song-title span{
    font-weight: 400;
    font-style: italic;
  }
  .controls {
    display: flex;
    justify-content: center;
    padding: 30px 15px;
    align-items: center;
  }
  button{
    appearance: none;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  button:hover{
    opacity: 0.8;
  }
  .play, .pause{
    font-size: 20px;
    font-weight: 700;
    padding: 15px 25px;
    margin: 0px 15px;
    border-radius: 8px;
    color: #FFF;
    background-color: #CC2E5D;
  }
  .next, .prev{
    font-size: 16px;
    font-weight: 700;
    padding: 10px 20px;
    margin: 0px 15px;
    border-radius: 6px;
    color: #FFF;
    background-color: #FF5858;
  }
  .playlist {
    padding: 0px 30px;
  }
  .playlist h3 {
    color: #212121;
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 30px;
    text-align: center;
  }
  .playlist .song{
    display: block;
    width: 100%;
    padding: 15px;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
  }
  .playlist .song:hover {
    color: #ff58ff
  }
  .playlist .song.playing{
    color: #FFF;
    background-image: linear-gradient(to right, #cc2e5D, #ff58ff)
  }
</style>
